<template>
    <div class="container" v-if="sessionData">
        <h1>{{ $t('aml.signature.heading') }}</h1>

        <div class="row mt-3">
            <div class="col-12 col-md-6">
                <loader-overlay
                    :loading="loading"
                >
                    <contract-sign-form
                        v-if="!isSigned"
                        :clientType="clientType"
                        :executives="executives"
                        :signaturePhone="signaturePhone"
                        @signed="onSigned"
                    />
                </loader-overlay>
            </div>
        </div>
    </div>
</template>

<script>
import ContractSignForm from './Components/ContractSignForm.vue'
import ViewBase from '../../../ViewBase.vue'
export default {
    name: 'Signature',
    extends: ViewBase,
    components: {
        ContractSignForm
    },
    data () {
        return {
            loading: true,
            isSigned: false,
            isSent: false
        }
    },
    computed: {
        executives () {
            return ((this.sessionData && this.clientType === 'PO') ? (this.sessionData.companyPeople || []) : [])
        },
        signaturePhone () {
            return ((this.sessionData && this.clientType !== 'PO') ? this.sessionData.basicData.phone : undefined)
        }
    },
    mounted () {
        this.checkSignature()
            .then(() => {
                if (this.isSigned) {
                    this.onSigned()
                }
            })
    },
    methods: {
        async checkSignature () {
            this.loading = true
            try {
                await this.$api.amlSessionSignatures.checkSignature()
                this.isSigned = true
            } catch (error) {
                // nothing
            } finally {
                this.$nextTick(() => {
                    this.loading = false
                })
            }
        },
        async onSigned () {
            await this.checkSignature()
            await this.$api.amlSession.closeSession()
            this.$emit('clear-session')
            this.$router.replace({
                name: 'SessionDataSent'
            })
        }
    }
}
</script>
