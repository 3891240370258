<template>
    <div>
        <loader-overlay
            :loading="loading"
        >
            <div class="p-4 rounded bg-white shadow">
                <h4>{{$t('signForm.heading')}}</h4>

                <b-form
                    @submit.prevent="onSendCode"
                    v-if="!codeSent"
                >
                    <form-input-select
                        :id="'signForm.selectedExecutive'"
                        :label="$t('signForm.selectExecutive')"
                        v-if="clientType === 'PO'"
                        v-model="selectedExecutive"
                        :options="executivesOptions"
                    />

                    <b-btn
                        type="submit"
                        variant="primary"
                        :disabled="$v.selectedExecutive.$invalid"
                    >
                        {{$t('signForm.sendCode')}}
                    </b-btn>
                </b-form>

                <b-form
                    @submit.prevent="onValidateCode"
                    v-else
                >
                    <p class="alert alert-success">
                        {{$t('signForm.codeSent', [ phone ])}}
                    </p>

                    <form-input
                        id="signForm.code"
                        :label="$t('signForm.codeInputLabel')"
                        v-model="code"
                        :validation="$v.code"
                        :hint="$t('signForm.resendHint')"
                    />

                    <div>
                        <b-btn
                            type="submit"
                            variant="primary"
                            class="mr-4"
                        >
                            {{$t('signForm.validateCode')}}
                        </b-btn>

                        <b-btn
                            @click.prevent="onSendCode"
                            variant="secondary"
                            :disabled="sendCooldown"
                        >
                            {{$t('signForm.resend')}}
                        </b-btn>
                    </div>
                </b-form>
            </div>
        </loader-overlay>
    </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
    name: 'ContractSignForm',
    props: {
        clientType: {
            type: String,
            required: true
        },
        executives: {
            type: Array,
            required: false,
            default: () => []
        },
        signaturePhone: {
            type: String,
            required: false,
            default: () => null
        }
    },
    data () {
        return {
            selectedExecutive: null,
            code: null,
            sendCooldown: false,
            loading: false,
            codeSent: false,
            sentSignature: null
        }
    },
    computed: {
        executivesOptions () {
            return this.executives.filter(item => item.executive).map(item => ({
                value: item._id,
                text: `${item.name} ${item.surname} - ${item.phone}`
            }))
        },
        phone () {
            if (this.clientType === 'PO') {
                const selected = this.executives.filter(item => item._id === this.selectedExecutive)
                return selected[0].phone || null
            }
            return this.signaturePhone
        }
    },
    methods: {
        async onValidateCode () {
            this.$v.$reset()

            if (this.$v.code.$invalid) {
                this.$v.$touch()
                this.$notify.error(this.$t('signForm.codeFormatInvalid'))
                return
            }

            if (!this.sentSignature) {
                this.$notify.error(this.$t('signForm.cannotVerifyUnsentCode'))
                return
            }

            try {
                await this.$api.amlSessionSignatures.update(this.sentSignature._id, {
                    code: this.code,
                    executiveId: this.sentSignature.companyPerson || null
                })
                this.$emit('signed')
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('signForm.invalidCode'))
            } finally {
                this.$nextTick(() => {
                    this.loading = false
                })
            }
        },
        async onSendCode () {
            this.$v.$reset()

            if (this.$v.selectedExecutive.$invalid) {
                this.$v.$touch()
                return
            }

            this.sendCooldown = true
            setTimeout(() => {
                this.sendCooldown = false
            }, 5000)
            this.loading = true
            try {
                const response = await this.$api.amlSessionSignatures.create({ executiveId: this.selectedExecutive })
                this.sentSignature = { ...response.data }
                this.codeSent = true
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('signForm.cannotIssueSmsCode'))
            } finally {
                this.$nextTick(() => {
                    this.loading = false
                })
            }
        }
    },
    validations: {
        code: {
            required
        },
        selectedExecutive: {
            required: requiredIf(model => model.clientType === 'PO')
        }
    }
}
</script>
